import { useState, useCallback } from "react";

const useVisibilityToggle = (
  initialState: boolean = false
): { isOpen: boolean; open: () => void; close: () => void } => {
  const [isOpen, setIsOpen] = useState(initialState);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  return { open, close, isOpen };
};

export { useVisibilityToggle };
