import { useCallback, ReactElement } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import * as ACTION_TYPES from "../actionTypes";
import { StartTestAction, ToggleViewAction } from "../types";

const InstructionItem = ({ children }: { children: string }): ReactElement => {
  return (
    <li>
      <Typography
        variant="h3"
        sx={{
          fontFamily: "Mulish",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "18px",
          lineHeight: "162%",
          letterSpacing: "0.005em",
          color: "#3C3A3A",
        }}
      >
        {children}
      </Typography>
    </li>
  );
};

const Instruction = ({
  onAction,
  hasTestStarted,
}: {
  onAction: (action: StartTestAction | ToggleViewAction) => void;
  hasTestStarted: boolean;
}): ReactElement => {
  const onClick = useCallback(() => {
    if (hasTestStarted) {
      return onAction({
        type: ACTION_TYPES.TOGGLE_VIEW,
      });
    }
    return onAction({
      type: ACTION_TYPES.START_TEST,
    });
  }, [hasTestStarted, onAction]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: theme => theme.spacing(9),
        boxShadow: "0px 4px 50px rgba(0, 0, 0, 0.03)",
        background: "#FFFFFF",
        width: theme => theme.spacing(144),
        height: theme => theme.spacing(120),
      }}
      paddingTop={15}
      paddingBottom={15}
      paddingLeft={12}
      paddingRight={12}
    >
      <Typography
        variant="h1"
        sx={{
          textAlign: "center",
          fontFamily: "lato",
          color: "#386FB2",
          fontWeight: 800,
          fontSize: "26px",
          lineHeight: "24px",
        }}
        marginBottom={18}
      >
        Instructions
      </Typography>
      <ul>
        <InstructionItem>Test Time is 60 minutes.</InstructionItem>
        <InstructionItem>
          All questions are multiple choice questions.
        </InstructionItem>
        <InstructionItem>
          Out of the 4 options given in each question, only 1 option is correct.
        </InstructionItem>
        <InstructionItem>
          Each question carries different marks as stated besides question.
        </InstructionItem>
        <InstructionItem>
          There are no negative markings for any questions.
        </InstructionItem>
        <InstructionItem>
          Do not forget to submit the test before closing the window or logging
          out.
        </InstructionItem>
        <InstructionItem>
          No candidate should adopt any unfair means, or induldge in any unfair
          examination practices as you are under surveillance.
        </InstructionItem>
      </ul>
      <Button
        variant="contained"
        size="large"
        sx={{
          marginTop: theme => theme.spacing(20),
          borderRadius: theme => theme.spacing(3),
          backgroundColor: "#386FB2",
          height: theme => theme.spacing(15),
        }}
        onClick={onClick}
      >
        <Box
          component="span"
          sx={{
            fontSize: theme => theme.spacing(4.5),
            fontWeight: 600,
            lineHeight: theme => theme.spacing(6),
            textTransform: "none",
          }}
        >
          {hasTestStarted ? "Go Back" : "Start Test"}
        </Box>
      </Button>
    </Box>
  );
};

export { Instruction };
