import { useCallback, useEffect, useState } from 'react';

export const useRestrictCopy = ({
  copyAttemptTimeout = 2000,
}: {
  copyAttemptTimeout?: number;
}): { isCopyAttempted: boolean; ignoreCopyAttempt: () => void } => {
  const [isCopyAttempted, setIsCopyAttempted] = useState(false);

  useEffect(() => {
    const handleContextMenu = (event: MouseEvent) => {
      event.preventDefault();
    };

    const handleCopyAttempt = (event: ClipboardEvent) => {
      event.preventDefault();
      setIsCopyAttempted(true);
      setTimeout(() => setIsCopyAttempted(false), copyAttemptTimeout);
    };
    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('copy', handleCopyAttempt);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('copy', handleCopyAttempt);
    };
  }, [setIsCopyAttempted, copyAttemptTimeout]);

  const ignoreCopyAttempt = useCallback(() => {
    setIsCopyAttempted(false);
  }, []);

  return { isCopyAttempted, ignoreCopyAttempt };
};
