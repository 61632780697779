import { createContext, useContext } from "react";

type DeviceContextType = {
  isDesktop: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isPhone: boolean;
};

const DeviceContext = createContext<DeviceContextType>({
  isDesktop: true,
  isMobile: false,
  isTablet: false,
  isPhone: false,
});

export const useDevice = (): DeviceContextType => useContext(DeviceContext);

export const DeviceProvider = DeviceContext.Provider;
