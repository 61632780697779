import { useState, useRef, useEffect } from "react";

const useTestTimer = (totalTestTime: number): number => {
  const intervalRef = useRef<NodeJS.Timer | null>(null);
  const [remainingTestTime, setRemainingTestTime] = useState(
    totalTestTime <= 0 ? 0 : totalTestTime
  );

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setRemainingTestTime(remainingTimer =>
        remainingTimer === 0 ? remainingTimer : remainingTimer - 1
      );
    }, 1000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (remainingTestTime === 0 && intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  }, [remainingTestTime]);

  return remainingTestTime;
};

export { useTestTimer };
