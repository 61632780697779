import { ReactElement } from "react";

import Box from "@mui/material/Box";

import { QUESTION_STATE } from "../constants";

type ValueOf<T extends object> = T[keyof T];

const AnsweredTile = ({ index }: { index?: number }): ReactElement => (
  <Box
    sx={{
      height: "40px",
      width: "40px",
      background: "#6DA831",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "8px",
    }}
    data-index={index}
  >
    {index ? `Q${index}` : null}
  </Box>
);

const UnAnsweredTile = ({ index }: { index?: number }): ReactElement => (
  <Box
    sx={{
      height: "40px",
      width: "40px",
      background: "#D8372B",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "8px",
    }}
    data-index={index}
  >
    {index ? `Q${index}` : null}
  </Box>
);

const UnVisitedTile = ({ index }: { index?: number }): ReactElement => (
  <Box
    sx={{
      height: "40px",
      width: "40px",
      background: "#C4C4C4",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "8px",
      color: "#386FB2",
    }}
    data-index={index}
  >
    {index ? `Q${index}` : null}
  </Box>
);

const ReviewTile = ({ index }: { index?: number }): ReactElement => (
  <Box
    sx={{
      height: "40px",
      width: "40px",
      background: "#775A9B",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "8px",
    }}
    data-index={index}
  >
    {index ? `Q${index}` : null}
  </Box>
);

const AnsweredToBeReviewedTile = ({
  index,
}: {
  index?: number;
}): ReactElement => (
  <Box
    sx={{
      height: "40px",
      width: "40px",
      background: "#775A9B",
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "8px",
    }}
    data-index={index}
  >
    <Box
      sx={{
        height: "15px",
        width: "15px",
        borderRadius: "100%",
        position: "absolute",
        top: "25px",
        left: "25px",
        background: "#6DA831",
      }}
    ></Box>
    {index ? `Q${index}` : null}
  </Box>
);

const QUESTION_STATE_VS_COMPONENT = {
  [QUESTION_STATE.ANSWERED]: AnsweredTile,
  [QUESTION_STATE.UNANSWERED]: UnAnsweredTile,
  [QUESTION_STATE.NOT_VISITED]: UnVisitedTile,
  [QUESTION_STATE.TO_BE_REVIEWD]: ReviewTile,
  [QUESTION_STATE.ANSWERED_AND_MARKED_FOR_REVIEW]: AnsweredToBeReviewedTile,
};

const QuestionTile = ({
  index,
  selected,
  type,
}: {
  index?: number;
  selected?: boolean;
  type: ValueOf<typeof QUESTION_STATE>;
}): ReactElement => {
  const Component = QUESTION_STATE_VS_COMPONENT[type];
  return (
    <Box
      sx={{
        fontFamily: "Mulish",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "150%",
        color: "white",
        marginBottom: "4px",
        borderRadius: "8px",
        ...(selected
          ? {
              border: "3px solid #386FB2",
            }
          : {}),
      }}
    >
      <Component index={index} />
    </Box>
  );
};

export { QuestionTile };
