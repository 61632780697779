import { memo, ReactElement } from "react";

import TextField, { TextFieldProps } from "@mui/material/TextField";
import styled from "@mui/material/styles/styled";

const TextInput = styled(TextField)(({ theme }) => ({
  "& label.Mui": {
    color: "rgba(3, 0, 71, 0.5)",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#386FB2",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: theme.spacing(3),
    "& fieldset": {
      borderColor: "#386FB2",
    },
    "&:hover fieldset": {
      borderColor: "#386FB2",
    },
  },
  "& .MuiFormLabel-root": {
    fontSize: theme.spacing(4),
    "&.MuiInputLabel-shrink": {
      fontSize: theme.spacing(5),
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    fontSize: theme.spacing(5),
  },
  "& .MuiFormHelperText-contained": {
    fontSize: theme.spacing(4),
  },
}));

const TextInputWithDefaultProps = memo(
  (props: TextFieldProps): ReactElement => {
    return (
      <TextInput
        inputProps={{ autoComplete: "new-password", style: { fontSize: 16 } }}
        {...props}
      />
    );
  }
);

export { TextInputWithDefaultProps as TextInput };
