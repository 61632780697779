export const FORM_FIELD_ENUM = {
  NAME: "name",
  MOBILE: "mobile",
  EMAIL: "email",
  PASSWORD: "password",
  HIGHEST_QUALIFICATION: "highest_qualification",
  CURRENT_SALARY: "current_salary",
  EXPECTED_SALARY: "expected_salary",
  REASON_TO_LEAVE: "reason_to_leave",
  JOINING_DATE: "joining_date",
  DEPARTMENT_ID: "department_id",
  SOFTWARES: "softwares_known",
  REFERENCE: "reference",
  EXPERIENCE: "experience",
  NOTICE_PERIOD: "notice_period",
  CV: "cv_file",
  PHOTO: "photo",
} as const;

export const REQUIRED_FIELDS = [
  FORM_FIELD_ENUM.NAME,
  FORM_FIELD_ENUM.MOBILE,
  FORM_FIELD_ENUM.EMAIL,
  FORM_FIELD_ENUM.PASSWORD,
  FORM_FIELD_ENUM.HIGHEST_QUALIFICATION,
  FORM_FIELD_ENUM.CURRENT_SALARY,
  FORM_FIELD_ENUM.EXPECTED_SALARY,
  FORM_FIELD_ENUM.REASON_TO_LEAVE,
  FORM_FIELD_ENUM.DEPARTMENT_ID,
  FORM_FIELD_ENUM.SOFTWARES,
  FORM_FIELD_ENUM.EXPERIENCE,
  FORM_FIELD_ENUM.REFERENCE,
  FORM_FIELD_ENUM.NOTICE_PERIOD,
  FORM_FIELD_ENUM.CV,
  FORM_FIELD_ENUM.PHOTO,
];
