import { useCallback, ReactElement, memo } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ButtonBase from "@mui/material/ButtonBase";

import { QuestionTile } from "./QuestionTile";

import { useVisibilityToggle } from "hooks/useVisibilityToggle";

import { QUESTION_STATE } from "../constants";
import * as ACTION_TYPES from "../actionTypes";

import { TestAreaAction } from "../types";

type ValueOf<T extends object> = T[keyof T];

const RightPane = memo(
  ({
    answersState,
    onAction,
    selectedQuestionIndex,
  }: {
    selectedQuestionIndex: number;
    answersState: { id: string; state: ValueOf<typeof QUESTION_STATE> }[];
    onAction: (action: TestAreaAction) => void;
  }): ReactElement => {
    const { isOpen, open, close } = useVisibilityToggle(false);
    const onQuestionClick = useCallback(
      (event: any) => {
        //@ts-ignore
        const questionIndex = event.target.dataset.index;
        if (questionIndex) {
          onAction({
            type: ACTION_TYPES.NAVIGATE_TO_QUESTION,
            payload: {
              questionIndex: questionIndex - 1,
            },
          });
        }
      },
      [onAction]
    );

    const handleSubmit = useCallback(() => {
      onAction({ type: ACTION_TYPES.TEST_AREA_SUBMIT });
      close();
    }, [onAction, close]);

    return (
      <Stack
        direction="column"
        sx={{
          flexShrink: 0,
          width: "284px",
        }}
        spacing={2}
        paddingLeft={6}
        paddingRight={6}
        paddingTop={6}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <QuestionTile type={QUESTION_STATE.ANSWERED} />
          <Typography
            marginLeft={2}
            sx={{ fontWeight: 700, fontSize: "16px", lineHeight: "150%" }}
          >
            Answered
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <QuestionTile type={QUESTION_STATE.UNANSWERED} />
          <Typography
            marginLeft={2}
            sx={{ fontWeight: 700, fontSize: "16px", lineHeight: "150%" }}
          >
            Not Answered
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <QuestionTile type={QUESTION_STATE.NOT_VISITED} />
          <Typography
            marginLeft={2}
            sx={{ fontWeight: 700, fontSize: "16px", lineHeight: "150%" }}
          >
            Not Visited
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <QuestionTile type={QUESTION_STATE.TO_BE_REVIEWD} />
          <Typography
            marginLeft={2}
            sx={{ fontWeight: 700, fontSize: "16px", lineHeight: "150%" }}
          >
            To be reviewed
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <QuestionTile type={QUESTION_STATE.ANSWERED_AND_MARKED_FOR_REVIEW} />
          <Typography
            marginLeft={2}
            sx={{ fontWeight: 700, fontSize: "16px", lineHeight: "150%" }}
          >
            Answered and marked for review
          </Typography>
        </Box>
        <Divider />
        <Grid container direction="row" spacing={2} marginTop={5}>
          {answersState.map((answer, index) => (
            <Grid item md={2} xs={6}>
              <ButtonBase key={answer.id} onClick={onQuestionClick}>
                <QuestionTile
                  index={index + 1}
                  type={answer.state}
                  selected={selectedQuestionIndex === index}
                />
              </ButtonBase>
            </Grid>
          ))}
        </Grid>
        <Button
          variant="outlined"
          sx={{
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "150%",
            borderRadius: "8px",
            textTransform: "none",
          }}
          onClick={open}
        >
          Submit test
        </Button>
        <Dialog
          open={isOpen}
          onClose={close}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              sx={{ fontWeight: 500, fontSize: "20px", lineHeight: "150%" }}
            >
              Are you sure you want to submit the test?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={close}
              color="error"
              variant="outlined"
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "150%",
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              autoFocus
              color="success"
              variant="outlined"
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "150%",
                textTransform: "none",
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
    );
  }
);

export { RightPane };
