import { ReactElement, ReactChild } from "react";

import Box from "@mui/material/Box";
import { Header } from "components/Header";

const PageLayout = ({
  children,
  onLogout,
}: {
  children?: ReactChild[] | ReactChild;
  onLogout?: () => void;
}): ReactElement => {
  return (
    <Box
      component="main"
      sx={{
        width: "100vw",
        height: "100vh",
        position: "relative",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header onLogout={onLogout} />
      <Box sx={{ flex: "1 1 0" }}>{children}</Box>
    </Box>
  );
};

export { PageLayout };
