import { DeviceProvider } from 'modules/device/contexts/deviceContext';
import { ViewportSizeContextProvider } from 'modules/device/contexts/viewPortSizeContext';

import { useDeviceDetect } from 'modules/device/hooks/useDeviceDetect';
import { useViewportSize } from 'modules/device/hooks/useViewportSize';

import { ReactElement } from 'react';

export const DeviceAndViewportSizeProvider = ({
  children,
  userAgent,
}: {
  children: ReactElement;
  userAgent?: string;
}): ReactElement => {
  const deviceValue = useDeviceDetect(userAgent);
  const viewportSize = useViewportSize(deviceValue);

  return (
    <DeviceProvider value={deviceValue}>
      <ViewportSizeContextProvider value={viewportSize}>{children}</ViewportSizeContextProvider>
    </DeviceProvider>
  );
};

export function withDeviceAndViewportSize<T extends JSX.IntrinsicAttributes>(
  PageComponent: (props: T) => ReactElement
): (props: T) => ReactElement {
  const WithDeviceAndViewportSize = (props: T): ReactElement => (
    <DeviceAndViewportSizeProvider userAgent={window.navigator.userAgent}>
      <PageComponent {...(props as T)} />
    </DeviceAndViewportSizeProvider>
  );

  return WithDeviceAndViewportSize;
}
