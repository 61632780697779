import { useRef, useEffect, useCallback } from "react";

type DispatchFn<T> = (action: T) => void;

const useSafeDispatch = <T>(dispatch: DispatchFn<T>): DispatchFn<T> => {
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return useCallback(
    (...args) => {
      if (isMounted.current) {
        dispatch(...args);
      }
    },
    [dispatch]
  );
};

export { useSafeDispatch };
