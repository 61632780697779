import { ReactElement, useCallback } from 'react';

import Box from '@mui/material/Box';
import LogoutIcon from '@mui/icons-material/Logout';
import ButtonBase from '@mui/material/ButtonBase';

import { useStickyElement } from 'hooks/useStickElements';
import { useNavigate } from 'react-router-dom';

import fullLogo from 'images/fullLogo.png';

const Header = ({ onLogout }: { onLogout?: () => void }): ReactElement => {
  const { setStickySentinelRef, isSticky } = useStickyElement();

  const navigate = useNavigate();
  const onClickLogo = useCallback(() => {
    if (localStorage.getItem('user')) {
      navigate('../test');
      return;
    }
    navigate('../login');
  }, [navigate]);

  return (
    <>
      <Box ref={setStickySentinelRef} sx={{ display: 'invisible' }} />
      <Box
        sx={{
          width: '100vw',
          height: theme => theme.spacing(24),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderRadius: theme => theme.spacing(1),
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          zIndex: 1000,
          boxShadow: theme =>
            isSticky ? `0px ${theme.spacing(1)} ${theme.spacing(1)} rgba(0, 0, 0, 0.25)` : undefined,
        }}
      >
        <Box margin={4} onClick={onClickLogo} sx={{ cursor: 'pointer' }}>
          <img src={fullLogo} alt="valserve log" />
        </Box>
        {onLogout ? (
          <ButtonBase onClick={onLogout}>
            <LogoutIcon
              sx={{
                height: '20px',
                width: '20px',
                margin: theme => theme.spacing(4),
                marginRight: theme => theme.spacing(10),
              }}
            />
          </ButtonBase>
        ) : null}
      </Box>
    </>
  );
};

export { Header };
