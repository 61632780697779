//lib
import {
  useReducer,
  useEffect,
  useCallback,
  Fragment,
  ReactNode,
  HTMLAttributes,
  SyntheticEvent,
} from "react";

//components
import { TextInput } from "./TextInput";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { TextFieldProps } from "@mui/material/TextField";
import { Typography } from "@mui/material";

//hooks
import { useVisibilityToggle } from "hooks/useVisibilityToggle";
import { useSafeDispatch } from "hooks/useSafeDispatch";

type Option = {
  id: string;
  name: string;
};

type State = {
  loading: boolean;
  error?: string;
  options: Option[];
};

type StartFetchingAction = {
  type: "START_FETCHING";
};
type FetchSuccessAction = {
  type: "FETCH_SUCCESS";
  payload: {
    options: Option[];
  };
};
type FetchErrorAction = {
  type: "FETCH_ERROR";
  payload: {
    error: any;
  };
};

type Action = StartFetchingAction | FetchSuccessAction | FetchErrorAction;

const reducer = (previousState: State, action: Action): State => {
  switch (action.type) {
    case "START_FETCHING": {
      return {
        loading: true,
        options: [],
      };
    }
    case "FETCH_SUCCESS": {
      return {
        loading: false,
        options: action.payload.options,
      };
    }
    case "FETCH_ERROR": {
      return {
        loading: false,
        options: [],
        error: action.payload.error?.message ?? "Something went wrong!",
      };
    }
    default:
      return previousState;
  }
};

const renderOption = (
  props: HTMLAttributes<HTMLLIElement>,
  option: Option
): ReactNode => {
  return (
    <li {...props}>
      <Typography variant="h5">{option.name}</Typography>
    </li>
  );
};

const AsyncSelect = ({
  id,
  fetchOptions,
  parseOptions,
  textInputProps,
  value,
  error,
  onSelect,
}: {
  id: string;
  value: any;
  error: boolean;
  fetchOptions: () => Promise<any[]>;
  parseOptions?: (fetchedOptions: any[]) => Option[];
  textInputProps?: TextFieldProps;
  onSelect: (event: { target: { id: string; value: any } }) => void;
}) => {
  const { isOpen, close, open } = useVisibilityToggle(false);
  const [state, dispatch] = useReducer(reducer, { loading: true, options: [] });

  const safeDispatch = useSafeDispatch(dispatch);

  const fetchData = useCallback(async () => {
    safeDispatch({ type: "START_FETCHING" });
    try {
      const fetchedOptions = await fetchOptions();
      const parsedOptions =
        parseOptions?.(fetchedOptions) ?? (fetchedOptions as Option[]);
      safeDispatch({
        type: "FETCH_SUCCESS",
        payload: { options: parsedOptions },
      });
    } catch (e) {
      safeDispatch({
        type: "FETCH_ERROR",
        payload: { error: e },
      });
    }
  }, [safeDispatch, fetchOptions, parseOptions]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onChange = useCallback(
    (event: SyntheticEvent, value: { id: string; name: string } | null) => {
      onSelect({
        ...event,
        target: {
          id,
          value: value?.id,
        },
      });
    },
    [onSelect, id]
  );

  return (
    <Autocomplete
      id={id}
      open={isOpen}
      onOpen={open}
      onClose={close}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={option => option.name}
      options={state.options}
      loading={state.loading}
      renderOption={renderOption}
      onChange={onChange}
      renderInput={params => (
        <TextInput
          label="Asynchronus"
          {...params}
          {...textInputProps}
          InputProps={{
            style: { fontSize: 16 },
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {state.loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
          error={error}
        />
      )}
    />
  );
};

export { AsyncSelect };
