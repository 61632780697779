import {
  createContext,
  useState,
  useContext,
  useMemo,
  useCallback,
  ReactElement,
  ReactNode,
} from "react";
import { useVisibilityToggle } from "hooks/useVisibilityToggle";

import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";

export type NotificationContextType = {
  onSuccess: (m: string) => void;
  onError: (m: string) => void;
  onInfo: (m: string) => void;
  onWarning: (m: string) => void;
};

const NotificationContext = createContext<NotificationContextType>({
  onSuccess: () => {},
  onError: () => {},
  onInfo: () => {},
  onWarning: () => {},
});

const NotificationContextProvider = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const [{ message, severity }, setState] = useState<{
    message?: string;
    severity?: "success" | "info" | "error" | "warning";
  }>({});
  const { open: show, close: hide, isOpen: isVisible } = useVisibilityToggle();

  const onSuccess = useCallback(
    (message: string): void => {
      show();
      setState({ message, severity: "success" });
    },
    [show]
  );

  const onInfo = useCallback(
    (message: string): void => {
      show();
      setState({ message, severity: "info" });
    },
    [show]
  );

  const onError = useCallback(
    (message: string): void => {
      show();
      setState({ message, severity: "error" });
    },
    [show]
  );

  const onWarning = useCallback(
    (message: string): void => {
      show();
      setState({ message, severity: "warning" });
    },
    [show]
  );

  const contextValue = useMemo(
    () => ({
      onSuccess,
      onInfo,
      onError,
      onWarning,
    }),
    [onError, onInfo, onSuccess, onWarning]
  );

  return (
    <>
      <NotificationContext.Provider value={contextValue}>
        {children}
      </NotificationContext.Provider>
      <Snackbar
        open={isVisible && !!severity && !!message}
        autoHideDuration={2000}
        onClose={hide}
      >
        <Alert onClose={hide} severity={severity} sx={{ width: "100%" }}>
          <Typography variant="h5" sx={{ fontFamily: "roboto" }}>
            {message}
          </Typography>
        </Alert>
      </Snackbar>
    </>
  );
};

const useNotificationContext = () => useContext(NotificationContext);

export { useNotificationContext, NotificationContextProvider };
