//lib
import { ReactElement, useState } from "react";

//components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import InputAdornment from "@mui/material/InputAdornment";
import { Link } from "react-router-dom";
import { TextInput } from "components/TextInput";
import { Header } from "components/Header";

//Icons
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

//hooks
import { useLogin } from "../hooks/useLogin";
import { useVisibilityToggle } from "hooks/useVisibilityToggle";

//images
import loginIllustration from "images/loginImage.png";

const WebLogin = (): ReactElement => {
  const [error, setError] = useState<string | undefined>();
  const handleLogin = useLogin({ onError: setError });
  const {
    isOpen: shouldShowPassword,
    open: showPassword,
    close: hidePassword,
  } = useVisibilityToggle(false);

  return (
    <Box component="main" sx={{ width: "100vw", height: "100vh" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <Header />
        <Box sx={{ display: "flex", flexDirection: "row", flex: "1 1 0" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: "1 1 0",
            }}
          >
            <img src={loginIllustration} alt="login illustrator"></img>
          </Box>
          <Box
            sx={{
              flex: "1 1 0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                maxHeight: "80%",
                maxWidth: "80%",
                height: theme => theme.spacing(125),
                width: theme => theme.spacing(125),
                borderRadius: theme => theme.spacing(9),
                boxShadow: theme =>
                  `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(
                    1
                  )} rgba(0, 0, 0, 0.03), -${theme.spacing(1)} -${theme.spacing(
                    1
                  )} ${theme.spacing(1)} rgba(0, 0, 0, 0.03)`,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  fontSize: theme => theme.spacing(9),
                  color: "#386FB2",
                  fontWeight: "800px",
                  fontFamily: "Lato",
                }}
              >
                Log In
              </Typography>
              <Box
                component="form"
                sx={{
                  marginTop: theme => theme.spacing(10),
                  width: "70%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                onSubmit={handleLogin}
              >
                <TextInput
                  fullWidth
                  margin="normal"
                  required
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  size="medium"
                  error={!!error}
                />
                <TextInput
                  fullWidth
                  margin="normal"
                  required
                  name="password"
                  label="Password"
                  type={shouldShowPassword ? "text" : "password"}
                  id="password"
                  size="medium"
                  error={!!error}
                  helperText={error}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {shouldShowPassword ? (
                          <VisibilityOff
                            sx={{ cursor: "pointer" }}
                            onClick={hidePassword}
                          />
                        ) : (
                          <Visibility
                            sx={{ cursor: "pointer" }}
                            onClick={showPassword}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{
                    marginTop: theme => theme.spacing(5),
                    borderRadius: theme => theme.spacing(3),
                    backgroundColor: "#386FB2",
                    height: theme => theme.spacing(10),
                  }}
                >
                  <Box
                    component="span"
                    sx={{
                      fontSize: theme => theme.spacing(4),
                      textTransform: "none",
                    }}
                  >
                    Sign In
                  </Box>
                </Button>
              </Box>
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  marginTop: theme => theme.spacing(5),
                  fontSize: theme => theme.spacing(3),
                }}
              >
                <Box
                  component="span"
                  sx={{
                    color: "rgba(0, 0, 0, 0.5)",
                  }}
                >
                  Don't have an account?
                </Box>
                <Link to="/signup">Sign up</Link>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { WebLogin };
