import { useEffect } from 'react';
import { useAsyncFn } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { useNotificationContext } from 'context/NotificationContext';

import { apiClient } from 'services/api';

const useLogoutHandler = (logoutNavigateUrl: string): (() => void) => {
  const navigate = useNavigate();
  const { onSuccess, onError } = useNotificationContext();

  const [state, handleLogout] = useAsyncFn(
    async () => {
      const dataResponse = await apiClient.post('api/v1/logout', {});
      return dataResponse.data;
    },
    [],
    { loading: false }
  );

  useEffect(() => {
    if (!state.loading && !state.error && !!state.value) {
      localStorage.removeItem('user');
      localStorage.removeItem('answerState');
      onSuccess(state.value.message || 'Successfully Logged out!');
      navigate(logoutNavigateUrl);
    }
    if (state.error) {
      onError(state.error.message);
    }
  }, [state, navigate, onSuccess, onError, logoutNavigateUrl]);

  return handleLogout;
};

export { useLogoutHandler };
