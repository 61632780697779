import { useMemo } from "react";
import useWindowSize from "react-use/lib/useWindowSize";
import _reduce from "lodash/reduce";

import { canUseDOM } from "fbjs/lib/ExecutionEnvironment";

type ViewportSize = {
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
};

const SCREENS = {
  sm: "640",
  md: "768",
  lg: "1024",
  xl: "1280",
};

export const useViewportSize = ({
  isTablet,
  isDesktop,
}: {
  isTablet: boolean;
  isDesktop: boolean;
}): ViewportSize => {
  const { width } = useWindowSize();

  return useMemo(() => {
    if (!canUseDOM) {
      return {
        sm: isTablet,
        md: isDesktop,
        lg: isDesktop,
        xl: false,
      };
    }
    return _reduce(
      SCREENS,
      (acc, screen, key) => ({ ...acc, [key]: width > parseInt(screen, 10) }),
      {} as ViewportSize
    );
  }, [width, isDesktop, isTablet]);
};
