import { Typography } from "@mui/material";
import { ReactElement } from "react";

const MobileSignup = (): ReactElement => {
  return (
    <Typography variant="h1">Use Desktop to access this website</Typography>
  );
};

export { MobileSignup };
