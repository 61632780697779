import { useState, useCallback, useRef, useEffect } from "react";

type StringAnyMap = { [x: string]: any };

const useForm = ({
  initialState,
  handleSubmit,
  onSubmitSuccess,
  validator,
}: {
  handleSubmit: (values: StringAnyMap) => Promise<void>;
  onSubmitSuccess: (values: StringAnyMap) => void;
  initialState?: StringAnyMap;
  validator?: (value: StringAnyMap) => StringAnyMap | undefined;
}): {
  values: StringAnyMap;
  errors: StringAnyMap | undefined;
  handleChange: (event: { target: { id: string; value: any } }) => void;
  handleFileChange: (id: string, file: File) => void;
  onSubmit: () => Promise<void>;
} => {
  const [values, setValues] = useState<StringAnyMap>(initialState ?? {});
  const [errors, setErrors] = useState<StringAnyMap | undefined>(undefined);

  const valueRef = useRef(values);
  valueRef.current = values;
  const errorsRef = useRef(errors);
  errorsRef.current = errors;

  const handleChange = useCallback(
    (event: { target: { id: string; value: any } }): void => {
      const { id, value } = event.target;
      setValues(prevValues => ({ ...prevValues, [id]: value }));
    },
    []
  );

  const handleFileChange = useCallback(
    (id: string, file: File) =>
      setValues(prevValues => ({ ...prevValues, [id]: file })),
    []
  );

  const onSubmit = useCallback(async () => {
    const _errors = validator?.(valueRef.current);

    if (_errors) {
      return setErrors(_errors as StringAnyMap);
    }
    setErrors(undefined);
    try {
      await handleSubmit(valueRef.current);
      onSubmitSuccess(valueRef.current);
    } catch (e) {
      setErrors((e as { errors: StringAnyMap }).errors as StringAnyMap);
    }
  }, [handleSubmit, onSubmitSuccess, validator]);

  useEffect(() => {
    if (errorsRef.current && validator) {
      setErrors(validator(values));
    }
  }, [validator, values]);

  return {
    values,
    errors,
    handleChange,
    handleFileChange,
    onSubmit,
  };
};

export { useForm };
