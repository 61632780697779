import { memo, ReactElement } from "react";
import { TestConfig } from "../types";

import { useTestArea } from "modules/test/hooks/useTestArea";

import { TestAreaView } from "./TestAreaView";

import { TestContainerAction } from "modules/test/types";

const TestAreaContainer = ({
  testConfig,
  onAction: _onAction,
}: {
  testConfig: TestConfig;
  onAction: (action: TestContainerAction) => void;
}): ReactElement => {
  const { remainingTestTime, onAction, answerState } = useTestArea({
    testConfig,
    onAction: _onAction,
  });

  return (
    <TestAreaView
      remainingTestTime={remainingTestTime}
      onAction={onAction}
      answerState={answerState}
      question={testConfig.questions[answerState.questionIndex]}
    />
  );
};

const MemoizedTestAreaContainer = memo(TestAreaContainer);
MemoizedTestAreaContainer.displayName = "TestAreaContainer";

export { MemoizedTestAreaContainer as TestAreaContainer };
