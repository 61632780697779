import { useEffect } from 'react';
import { useLoginHandler, LoginHandler } from './useLoginHandler';
import { useNavigate } from 'react-router-dom';

const useLogin = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (errorMessage?: string) => void;
}): LoginHandler => {
  const loginHandler = useLoginHandler({ onError, onSuccess });
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('user')) {
      navigate('../test');
    }
  }, [navigate]);
  return loginHandler;
};

export { useLogin };
