import { Box, Modal, Typography } from '@mui/material';
import { useRestrictCopy } from 'hooks/useRestrictCopy';

export const CopyRestrictPopover = () => {
  const { isCopyAttempted, ignoreCopyAttempt } = useRestrictCopy({ copyAttemptTimeout: 2000 });

  return (
    <Modal open={isCopyAttempted} onClose={ignoreCopyAttempt}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 2,
          p: 3,
          textAlign: 'center',
        }}
      >
        <Typography variant="h4" color="error" gutterBottom>
          Copying is not allowed!
        </Typography>
        <Typography variant="h5">This copying attempt is recorded and sent to the invigilator.</Typography>
      </Box>
    </Modal>
  );
};
