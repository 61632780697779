import { memo, ReactElement } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { AnswerInput } from "./AnswerInput";

import { TestAreaAction, QuestionConfig, Answer } from "../types";

const QuestionAndAnswerInput = ({
  prefix = "",
  question,
  index,
  onAction,
  answer,
}: {
  prefix: string;
  question: QuestionConfig;
  index: number;
  onAction: (action: TestAreaAction) => void;
  answer: Answer;
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Mulish",
            fontStyle: "normal",
            fontWeight: 1000,
            fontSize: "24px",
            lineHeight: "162%",
            color: "#386FB2",
          }}
        >
          {prefix ? `Q ${prefix}.${index}` : `Q ${index}`}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Mulish",
            fontStyle: "normal",
            fontWeight: 1000,
            fontSize: "24px",
            lineHeight: "162%",
            color: "#386FB2",
          }}
        >
          {`Marks: ${question.marks}`}
        </Typography>
      </Box>
      {question.question ? (
        <Box
          sx={{
            fontStyle: "normal",
            fontSize: "20px",
          }}
          marginTop={2}
          marginBottom={2}
        >
          <div dangerouslySetInnerHTML={{ __html: question.question }} />
        </Box>
      ) : null}
      {question.answerConfig ? (
        <AnswerInput
          path={prefix ? `${prefix}.${index}` : `${index}`}
          answerConfig={question.answerConfig}
          onAction={onAction}
          value={answer.answer}
        />
      ) : null}
      {question.subQuestions ? (
        <Stack spacing={10} direction="column" marginTop={10}>
          {question.subQuestions.map((subQuestion, sqIndex) => (
            <QuestionAndAnswerInput
              key={subQuestion.id}
              prefix={prefix ? `${prefix}.${index}` : `${index}`}
              question={subQuestion}
              index={sqIndex + 1}
              onAction={onAction}
              answer={answer.subAnswer![sqIndex]!}
            />
          ))}
        </Stack>
      ) : null}
    </Box>
  );
};

const LeftPane = memo(
  ({
    question,
    index,
    onAction,
    answer,
  }: {
    question: QuestionConfig;
    index: number;
    onAction: (action: TestAreaAction) => void;
    answer: Answer;
  }): ReactElement => {
    return (
      <QuestionAndAnswerInput
        prefix=""
        question={question}
        index={index}
        onAction={onAction}
        answer={answer}
      />
    );
  }
);

export { LeftPane };
