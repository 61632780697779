import { useMemo } from "react";
import MobileDetect from "mobile-detect";

export const useDeviceDetect = (
  userAgent?: string
): {
  isMobile: boolean;
  isDesktop: boolean;
  isPhone: boolean;
  isTablet: boolean;
} =>
  useMemo(() => {
    if (!userAgent) {
      return {
        isMobile: false,
        isDesktop: true,
        isPhone: false,
        isTablet: false,
      };
    }
    const md = new MobileDetect(userAgent);
    return {
      isMobile: !!md.mobile(),
      isDesktop: !md.mobile(),
      isPhone: !!md.phone(),
      isTablet: !!md.tablet(),
    };
  }, [userAgent]);
