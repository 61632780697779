import _times from "lodash/times";

import { AnswerConfig, Answer, QuestionConfig } from "./types";
import { QUESTION_TYPE, QUESTION_STATE } from "./constants";

function padTo2Digits(num: number): string {
  return num.toString().padStart(2, "0");
}

export function convertMsToTime(seconds: number): string {
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;

  // 👇️ If you don't want to roll hours over, e.g. 24 to 00
  // 👇️ comment (or remove) the line below
  // commenting next line gets you `24:00:00` instead of `00:00:00`
  // or `36:15:31` instead of `12:15:31`, etc.
  hours = hours % 24;

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
    seconds
  )}`;
}

export const getDefaultAnswer = (
  answerConfig: AnswerConfig | null
): string | Object[] | undefined => {
  const type = answerConfig?.type;
  if (!type) {
    return undefined;
  }
  return type === QUESTION_TYPE.TABLE
    ? _times(answerConfig.rows, index => ({ id: index + 1 }))
    : "";
};

export const getDefaultAnswerConfigForQuestion = (
  question: QuestionConfig
): Answer => ({
  id: question.id,
  type: question.answerConfig?.type,
  subAnswer: question.subQuestions
    ? getDefaultAnswerConfig(question.subQuestions)
    : undefined,
  answer: getDefaultAnswer(question.answerConfig),
  state: QUESTION_STATE.NOT_VISITED,
  hasChanged: false,
  markedForReview: false,
});

export const getDefaultAnswerConfig = (questions: QuestionConfig[]): Answer[] =>
  questions.map(getDefaultAnswerConfigForQuestion);

export const getUpdationSpecFromObject = (path: string, spec: any): any => {
  const splittedString = path.split(".");
  if (splittedString.length === 1) {
    return {
      [splittedString[0]]: spec,
    };
  }
  return {
    [splittedString[0]]: getUpdationSpecFromObject(
      splittedString.slice(1).join("."),
      spec
    ),
  };
};

type ValueOf<T> = T[keyof T];

export const getAnswerState = (
  answer: Answer
): ValueOf<typeof QUESTION_STATE> => {
  if (!answer.markedForReview) {
    return answer.hasChanged
      ? QUESTION_STATE.ANSWERED
      : QUESTION_STATE.UNANSWERED;
  }
  return answer.hasChanged
    ? QUESTION_STATE.ANSWERED_AND_MARKED_FOR_REVIEW
    : QUESTION_STATE.TO_BE_REVIEWD;
};

type AnswerDTO = {
  id: string;
  type: Answer["type"];
  answer: Answer["answer"];
  subAnswer: AnswerDTO[] | undefined;
};

const convertAnswerToDTO = (answer: Answer): AnswerDTO => ({
  id: answer.id,
  type: answer.type,
  answer: answer.answer,
  subAnswer: answer.subAnswer?.map(convertAnswerToDTO),
});

export const convertAnswersToDTO = (answers: Answer[]): AnswerDTO[] =>
  answers.map(convertAnswerToDTO);
