import { createContext, useContext } from "react";

export type ViewportSizeContextType = {
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
};

const ViewportSizeContext = createContext<ViewportSizeContextType>({
  sm: false,
  md: false,
  lg: false,
  xl: false,
});

export const useViewportSize = (): ViewportSizeContextType =>
  useContext(ViewportSizeContext);

export const ViewportSizeContextProvider = ViewportSizeContext.Provider;
