import { useEffect, ReactElement } from 'react';

import Box from '@mui/material/Box';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TestContainer } from 'modules/test/components/TestContainer';
import { PageLayout } from 'components/PageLayout';
import ErrorBoundary from 'components/ErrorBoundary';

//hooks
import { useIsMobileView } from 'modules/device/hooks/useIsMobileView';
import { useNavigate } from 'react-router-dom';
import { useLogoutHandler } from 'hooks/useLogoutHandler';
import { CopyRestrictPopover } from 'components/CopyRestrictPopover';

const TestWrapper = (): ReactElement => {
  const isMobileView = useIsMobileView();
  const logoutHandler = useLogoutHandler('../login');

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem('user')) {
      navigate('../login');
    }
  }, [navigate]);

  return (
    <>
      <PageLayout onLogout={logoutHandler}>
        {isMobileView ? (
          <Stack
            direction="column"
            spacing={4}
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <Typography variant="h3">Please use desktop website to attend the exam!</Typography>
            <Button
              variant="contained"
              size="large"
              sx={{
                marginTop: theme => theme.spacing(5),
                borderRadius: theme => theme.spacing(3),
                backgroundColor: '#386FB2',
                height: theme => theme.spacing(10),
              }}
              onClick={logoutHandler}
            >
              <Typography variant="h4" sx={{ textTransform: 'none' }}>
                Logout
              </Typography>
            </Button>
          </Stack>
        ) : (
          <Box
            sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ErrorBoundary>
              <TestContainer />
            </ErrorBoundary>
          </Box>
        )}
      </PageLayout>
      <CopyRestrictPopover />
    </>
  );
};

export default TestWrapper;
