import { useEffect } from 'react';
import { useAsyncFn } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { apiClient } from 'services/api';

import { TestConfig } from 'modules/test/types';

const useTestLazyQuery = (): {
  loading: boolean;
  error?: any;
  testConfig?: TestConfig;
  fetchTestConfig: () => Promise<TestConfig>;
} => {
  const navigate = useNavigate();

  const [{ loading, error, value: testConfig }, fetchTestConfig] = useAsyncFn<() => Promise<TestConfig>>(
    async () => {
      const questionResponse = await apiClient.get<TestConfig>('api/v1/questions');
      return questionResponse.data;
    },
    [],
    { loading: false }
  );

  const errorResponse = (error as unknown as any)?.response;

  useEffect(() => {
    if (errorResponse && errorResponse.statusText === 'Unauthorized') {
      localStorage.removeItem('user');
      navigate('../login');
    }
  }, [errorResponse, navigate]);

  return {
    loading,
    error,
    testConfig,
    fetchTestConfig,
  };
};

export { useTestLazyQuery };
