//lib
import { ReactElement } from "react";

//components
import { MobileLogin } from "modules/login/components/MobileLogin";
import { WebLogin } from "modules/login/components/WebLogin";

//hooks
import { useIsMobileView } from "modules/device/hooks/useIsMobileView";

export default function Login(): ReactElement {
  const isMobileView = useIsMobileView();

  return isMobileView ? <MobileLogin /> : <WebLogin />;
}
