import { ReactElement } from "react";

import { useLogoutHandler } from "hooks/useLogoutHandler";

import { PageLayout } from "components/PageLayout";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const NoAvailableTest = (): ReactElement => {
  const logoutHandler = useLogoutHandler("../login");

  return (
    <PageLayout onLogout={logoutHandler}>
      <Stack
        direction="column"
        spacing={4}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <Typography variant="h3">
          No tests available for you. Please contact Admin
        </Typography>
        <Button
          variant="contained"
          size="large"
          sx={{
            marginTop: theme => theme.spacing(5),
            borderRadius: theme => theme.spacing(3),
            backgroundColor: "#386FB2",
            height: theme => theme.spacing(10),
          }}
          onClick={logoutHandler}
        >
          <Typography variant="h4" sx={{ textTransform: "none" }}>
            Logout
          </Typography>
        </Button>
      </Stack>
    </PageLayout>
  );
};

export default NoAvailableTest;
