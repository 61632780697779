import { ReactElement, useCallback } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { PageLayout } from "components/PageLayout";

import writtingExam from "images/writtingExam.png";

import { useLogoutHandler } from "hooks/useLogoutHandler";

import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const useExamListQuery = () => {
  return {
    loading: false,
  };
};

const ExamCard = ({
  id,
  label,
}: {
  id: string;
  label: string;
}): ReactElement => {
  const navigate = useNavigate();
  const onClick = useCallback(() => navigate(`/test/${id}`), [navigate, id]);

  return (
    <Button
      sx={{
        height: theme => theme.spacing(15),
        width: "100%",
        borderRadius: theme => theme.spacing(3),
        borderLeft: "10px solid #386FB2",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
        paddingLeft: theme => theme.spacing(4),
        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03))",
        color: "black",
        ":hover": {
          bgcolor: "#386FB2",
          color: "white",
        },
      }}
      onClick={onClick}
    >
      <Typography variant="h5">{label}</Typography>
    </Button>
  );
};

type Exam = {
  id: string;
  label: string;
};

const Home = (): ReactElement => {
  const { loading } = useExamListQuery();
  const logoutHandler = useLogoutHandler("../login");
  const examList: Exam[] = [
    { id: "exam1", label: "JEE mock test 1" },
    { id: "exam2", label: "JEE mock test 2" },
    { id: "exam3", label: "JEE mock test 3" },
    { id: "exam4", label: "JEE mock test 4" },
    { id: "exam5", label: "JEE mock test 5" },
    { id: "exam6", label: "JEE mock test 6" },
    { id: "exam7", label: "JEE mock test 1" },
    { id: "exam8", label: "JEE mock test 2" },
    { id: "exam9", label: "JEE mock test 3" },
    { id: "exam10", label: "JEE mock test 4" },
    { id: "exam11", label: "JEE mock test 5" },
    { id: "exam12", label: "JEE mock test 6" },
  ];

  if (loading) {
    return (
      <PageLayout onLogout={logoutHandler}>
        <CircularProgress />
      </PageLayout>
    );
  }
  return (
    <PageLayout onLogout={logoutHandler}>
      <Box
        sx={{
          display: "flex",
          height: "100%",
          width: "100%",
          flexDirection: "row",
          position: "relative",
        }}
      >
        <Box
          sx={{
            flex: "1 1 0",
            marginLeft: theme => theme.spacing(20),
            marginRight: theme => theme.spacing(20),
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            marginTop={10}
            marginBottom={10}
            sx={{
              flexGrow: 1,
              width: "100%",
              borderRadius: theme => theme.spacing(9),
              boxShadow: "0px 4px 50px rgba(0, 0, 0, 0.03)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                color: "#386FB2",
                marginLeft: theme => theme.spacing(17),
                marginRight: theme => theme.spacing(17),
                marginBottom: theme => theme.spacing(8),
                marginTop: theme => theme.spacing(8),
              }}
            >
              List of exams
            </Typography>
            <Box
              paddingLeft={17}
              paddingRight={17}
              paddingBottom={8}
              sx={{ flex: "1 1 0", overflowY: "auto" }}
            >
              <Stack direction="column" spacing={6}>
                {examList.map(exam => (
                  <ExamCard key={exam.id} id={exam.id} label={exam.label} />
                ))}
              </Stack>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: theme => theme.spacing(20),
            marginLeft: theme => theme.spacing(20),
          }}
        >
          <img src={writtingExam} alt="exam list illustrator"></img>
        </Box>
      </Box>
    </PageLayout>
  );
};

export default Home;
