//lib
import { ReactElement, useState } from "react";

//components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import InputAdornment from "@mui/material/InputAdornment";
import { Link } from "react-router-dom";
import { TextInput } from "components/TextInput";

//Icons
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

//hooks
import { useLogin } from "../hooks/useLogin";
import { useVisibilityToggle } from "hooks/useVisibilityToggle";

//image
import loginIllustration from "images/loginImage.png";

const MobileLogin = (): ReactElement => {
  const [error, setError] = useState<string | undefined>();
  const handleLogin = useLogin({ onError: setError });
  const {
    isOpen: shouldShowPassword,
    open: showPassword,
    close: hidePassword,
  } = useVisibilityToggle(false);

  return (
    <Box component="main" sx={{ width: "100vw", height: "100vh" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            flex: "1 1 0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginBottom: 0,
          }}
          margin={8}
        >
          <img src={loginIllustration} alt="login illustrator" width="100%" />
        </Box>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            flex: "1 1 0",
            marginRight: theme => theme.spacing(4),
            marginLeft: theme => theme.spacing(4),
          }}
          onSubmit={handleLogin}
        >
          <Box sx={{ flex: "0 0 0" }}>
            <TextInput
              fullWidth
              margin="normal"
              required
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              size="medium"
              error={!!error}
              InputProps={{
                sx: {
                  fontSize: theme => theme.spacing(5),
                  lineHeight: theme => theme.spacing(6),
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: theme => theme.spacing(4.5),
                  lineHeight: theme => theme.spacing(6),
                },
              }}
            />
            <TextInput
              fullWidth
              margin="normal"
              required
              name="password"
              label="Password"
              type={shouldShowPassword ? "text" : "password"}
              id="password"
              size="medium"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {shouldShowPassword ? (
                      <VisibilityOff
                        sx={{ cursor: "pointer" }}
                        onClick={hidePassword}
                      />
                    ) : (
                      <Visibility
                        sx={{ cursor: "pointer" }}
                        onClick={showPassword}
                      />
                    )}
                  </InputAdornment>
                ),
                sx: {
                  fontSize: theme => theme.spacing(5),
                  lineHeight: theme => theme.spacing(6),
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: theme => theme.spacing(4.5),
                  lineHeight: theme => theme.spacing(6),
                },
              }}
              error={!!error}
              helperText={error}
            />
          </Box>
          <Box sx={{ flex: "1 1 0" }}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              size="large"
              sx={{
                marginTop: theme => theme.spacing(12),
                borderRadius: theme => theme.spacing(3),
                backgroundColor: "#386FB2",
                height: theme => theme.spacing(13),
              }}
            >
              <Box
                component="span"
                sx={{
                  fontSize: theme => theme.spacing(4),
                  textTransform: "none",
                }}
              >
                Sign In
              </Box>
            </Button>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                alignItems: "center",
                justifyContent: "center",
                fontSize: theme => theme.spacing(4),
                marginTop: theme => theme.spacing(2),
              }}
            >
              <Box
                component="span"
                sx={{
                  color: "rgba(0, 0, 0, 0.5)",
                }}
              >
                Don't have an account?
              </Box>
              <Link to="/signup">Sign up</Link>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { MobileLogin };
