import { useCallback, useEffect } from 'react';
import { useAsyncFn } from 'react-use';

import { useNavigate } from 'react-router-dom';

import { useNotificationContext } from 'context/NotificationContext';

//apiClient
import { apiClient } from 'services/api';

export type LoginHandler = (event: React.FormEvent<HTMLFormElement>) => void;

const useLoginHandler = ({
  onSuccess: _onSuccess,
  onError: _onError,
}: {
  onSuccess?: () => void;
  onError?: (errorMessage?: string) => void;
}): LoginHandler => {
  const navigate = useNavigate();
  const { onSuccess, onError } = useNotificationContext();

  const [state, login] = useAsyncFn(
    ({ email, password }: { email: string; password: string }) =>
      apiClient.post(
        'api/login',
        { email, password },
        {
          withCredentials: true,
          xsrfCookieName: 'XSRF-TOKEN',
          xsrfHeaderName: 'X-XSRF-TOKEN',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      ),
    []
  );
  const data = state.value;
  const error = state.error;

  useEffect(() => {
    if (data && data.status === 200) {
      localStorage.setItem('user', JSON.stringify(data.data.user));
      _onSuccess?.();
      onSuccess('Login successful');

      const navigateTo = data.data.user?.applicant?.test_taken === 'yes' ? '../noAvailableTest' : '../test';
      navigate(navigateTo);
    } else if (data) {
      const errorMessage = 'User already logged in somewhere else! Logout from there first.';
      _onError?.(errorMessage);
      onError(errorMessage);
    }
  }, [data, onSuccess, _onSuccess, onError, _onError, navigate]);

  useEffect(() => {
    if (error) {
      _onError?.((error as any).response?.data?.message ?? 'Something went wrong!');
      onError((error as { message: string })?.message ?? 'Something went wrong!');
    }
  }, [onError, _onError, error]);

  return useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      login({
        email: data.get('email') as string,
        password: data.get('password') as string,
      });
    },
    [login]
  );
};

export { useLoginHandler };
