import { useState, useCallback, useEffect, useRef } from "react";
import update from "immutability-helper";
import { useTestTimer } from "./useTestTimer";

import {
  getDefaultAnswerConfigForQuestion,
  getDefaultAnswerConfig,
  getUpdationSpecFromObject,
  getAnswerState,
} from "../utils";

import * as ACTION_TYPES from "../actionTypes";

import {
  TestAreaAction,
  Answer,
  TestConfig,
  TestContainerAction,
} from "../types";

const useTestArea = ({
  testConfig,
  onAction: _onAction,
}: {
  testConfig: TestConfig;
  onAction: (action: TestContainerAction) => void;
}): {
  remainingTestTime: number;
  onAction: (action: TestAreaAction) => void;
  answerState: { questionIndex: number; answers: Answer[] };
} => {
  const questions = testConfig.questions;
  const differenceFromStartTime = Date.now() - testConfig.start_time;
  const timeLeft = testConfig.duration - differenceFromStartTime;

  const remainingTestTime = useTestTimer(Math.floor(timeLeft / 1000));

  const [answerState, setAnswerState] = useState(
    () =>
      (JSON.parse(localStorage.getItem("answerState") || "false") || {
        answers: getDefaultAnswerConfig(questions),
        questionIndex: 0,
      }) as { questionIndex: number; answers: Answer[] }
  );
  const answerRef = useRef(answerState.answers);
  answerRef.current = answerState.answers;

  const onAction = useCallback(
    (action: TestAreaAction): void => {
      switch (action.type) {
        case ACTION_TYPES.NAVIGATE_TO_QUESTION: {
          setAnswerState(prevAnswerState => ({
            answers: [
              ...prevAnswerState.answers.slice(
                0,
                prevAnswerState.questionIndex
              ),
              {
                ...prevAnswerState.answers[prevAnswerState.questionIndex],
                state: getAnswerState(
                  prevAnswerState.answers[prevAnswerState.questionIndex]
                ),
              },
              ...prevAnswerState.answers.slice(
                prevAnswerState.questionIndex + 1
              ),
            ],
            questionIndex: action.payload.questionIndex,
          }));
          break;
        }
        case ACTION_TYPES.ANSWER_FIELD_CHANGE: {
          setAnswerState(prevAnswerState => {
            const rootAnswerIndex = action.payload.path[0];
            //@ts-ignore
            const updatedAnswers = update(
              prevAnswerState.answers,
              getUpdationSpecFromObject(
                action.payload.path.join(".subAnswer."),
                {
                  answer: { $set: action.payload.value },
                }
              )
            );
            updatedAnswers[rootAnswerIndex].hasChanged = true;

            return {
              ...prevAnswerState,
              answers: updatedAnswers,
            };
          });
          break;
        }
        case ACTION_TYPES.CLEAR_SECTION: {
          setAnswerState(prevAnswerState => {
            return update(prevAnswerState, {
              answers: {
                [prevAnswerState.questionIndex]: {
                  $set: getDefaultAnswerConfigForQuestion(
                    questions[prevAnswerState.questionIndex]
                  ),
                },
              },
            });
          });
          break;
        }
        case ACTION_TYPES.MARK_FOR_REVIEW: {
          setAnswerState(prevAnswerState => {
            return update(prevAnswerState, {
              answers: {
                [prevAnswerState.questionIndex]: {
                  markedForReview: {
                    $set: !prevAnswerState.answers[
                      prevAnswerState.questionIndex
                    ].markedForReview,
                  },
                  state: {
                    $set: getAnswerState({
                      ...prevAnswerState.answers[prevAnswerState.questionIndex],
                      markedForReview:
                        !prevAnswerState.answers[prevAnswerState.questionIndex]
                          .markedForReview,
                    }),
                  },
                },
              },
            });
          });
          break;
        }
        case ACTION_TYPES.TEST_AREA_SUBMIT: {
          _onAction({
            type: ACTION_TYPES.SUBMIT_TEST,
            payload: { answers: answerRef.current },
          });
          break;
        }
        case ACTION_TYPES.TOGGLE_VIEW: {
          _onAction(action);
          break;
        }
        default:
          break;
      }
    },
    [questions, _onAction]
  );

  useEffect(() => {
    if (remainingTestTime <= 0) {
      onAction({ type: ACTION_TYPES.TEST_AREA_SUBMIT });
    }
  }, [remainingTestTime, onAction]);

  useEffect(() => {
    localStorage.setItem("answerState", JSON.stringify(answerState));
  }, [answerState]);

  return {
    remainingTestTime,
    onAction,
    answerState,
  };
};

export { useTestArea };
