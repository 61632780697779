export const VIEW = {
  EXAM_COMPLETE: "EXAM_COMPLETE",
  INSTRUCTION: "INSTRUCTION",
  EXAMS: "EXAMS",
} as const;

export const QUESTION_STATE = {
  ANSWERED: "ANSWERED",
  UNANSWERED: "UNANSWERED",
  NOT_VISITED: "NOT_VISITED",
  TO_BE_REVIEWD: "TO_BE_REVIEWED",
  ANSWERED_AND_MARKED_FOR_REVIEW: "ANSWERED_AND_MARKED_FOR_REVIEW",
} as const;

export const QUESTION_TYPE = {
  MCQ: "mcq",
  TEXT: "text",
  TABLE: "table",
} as const;
