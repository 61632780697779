import { memo, useState, useRef, ReactElement, useCallback } from "react";

import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Webcam from "react-webcam";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ImageCaptureModal = ({
  isOpen,
  handleClose,
  handleSave,
}: {
  isOpen: boolean;
  handleClose: () => void;
  handleSave: (file: File) => void;
}): ReactElement => {
  const [picture, setPicture] = useState<string | null>(null);
  const imageRef = useRef<string | null>(null);
  imageRef.current = picture;

  const onSave = useCallback(async () => {
    if (!imageRef.current) {
      handleClose();
    }
    const res = await fetch(imageRef.current as string);
    const file = (await res.blob()) as File;
    handleSave(file);
    handleClose();
  }, [handleSave, handleClose]);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          bgcolor: "background.paper",
          border: "1px solid #000",
          padding: theme => theme.spacing(4),
          borderRadius: theme => theme.spacing(3),
          boxShadow: 24,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography id="modal-modal-title" variant="h3">
            Capture Image
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {!picture ? (
            <Webcam
              audio={false}
              screenshotFormat="image/jpeg"
              height={500}
              width={500}
              mirrored
            >
              {/*@ts-ignore*/}
              {({ getScreenshot }): JSX.Element => (
                <Button
                  variant="contained"
                  onClick={() => setPicture(getScreenshot())}
                >
                  <Box
                    component="span"
                    sx={{
                      fontSize: theme => theme.spacing(3.5),
                      textTransform: "none",
                    }}
                  >
                    Capture
                  </Box>
                </Button>
              )}
            </Webcam>
          ) : (
            <>
              <img
                src={picture}
                height={500}
                width={500}
                //@ts-ignore
                style={{ "object-fit": "contain" }}
              />
              <Stack direction="row" spacing={1}>
                <Button variant="contained" onClick={() => setPicture(null)}>
                  <Box
                    component="span"
                    sx={{
                      fontSize: theme => theme.spacing(3.5),
                      textTransform: "none",
                    }}
                  >
                    Retake
                  </Box>
                </Button>
                <Button variant="contained" color="success" onClick={onSave}>
                  <Box
                    component="span"
                    sx={{
                      fontSize: theme => theme.spacing(3.5),
                      textTransform: "none",
                    }}
                  >
                    Save image
                  </Box>
                </Button>
              </Stack>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

const MemoizedImageCaptureModal = memo(ImageCaptureModal);
MemoizedImageCaptureModal.displayName = "MemoizedImageCaptureModal";

export { MemoizedImageCaptureModal as ImageCaptureModal };
