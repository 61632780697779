import { useRef, useCallback, useMemo, ReactElement } from "react";

import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { TextInput } from "components/TextInput";
import { DataGrid, GridRowsProp, GridRowId } from "@mui/x-data-grid";

import { QUESTION_TYPE } from "../constants";
import * as ACTION_TYPES from "../actionTypes";

import { styled } from "@mui/material/styles";
import {
  AnswerConfig,
  TextAnswerConfig,
  MCQAnswerConfig,
  TableAnswerConfig,
  AnswerFieldChangeAction,
} from "../types";

const TextAnswerInput = ({
  answerConfig,
  value,
  onChange,
}: {
  answerConfig: TextAnswerConfig;
  value: string;
  onChange: (value: any) => void;
}): ReactElement => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange((event.target as HTMLInputElement).value);
    },
    [onChange]
  );

  return (
    <TextInput
      fullWidth
      margin="normal"
      label="Answer"
      autoFocus
      size="medium"
      multiline
      rows={6}
      style={{ textAlign: "left" }}
      value={value}
      onChange={handleChange}
    />
  );
};

const RadioComponentProps = {
  typography: {
    sx: {
      fontSize: "20px",
      fontStyle: "normal",
    },
  },
};

const MCQAnswerInput = ({
  answerConfig,
  value,
  onChange,
}: {
  answerConfig: MCQAnswerConfig;
  value: string;
  onChange: (value: any) => void;
}): ReactElement => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange((event.target as HTMLInputElement).value);
    },
    [onChange]
  );

  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        {answerConfig.cols.map(option => (
          <FormControlLabel
            key={option}
            value={option}
            control={
              <Radio
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 28,
                  },
                }}
              />
            }
            label={option}
            componentsProps={RadioComponentProps}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: `1px solid #386FB2`,
    borderBottom: `1px solid #386FB2`,
    "&:last-child": {
      borderRight: 0,
    },
  },
  "& .MuiDataGrid-editInputCell": {
    fontSize: "20px",
    fontStyle: "normal",
  },
}));

const TableAnswerInput = ({
  answerConfig,
  value,
  onChange,
}: {
  answerConfig: TableAnswerConfig;
  value: GridRowsProp[];
  onChange: (value: any) => void;
}): ReactElement => {
  const colConfig = answerConfig.cols;
  const valuesRef = useRef<GridRowsProp[]>(value);
  valuesRef.current = value;
  const columns = useMemo(
    () =>
      colConfig.map(col => ({
        field: col.id,
        headerName: col.label,
        editable: true,
        flex: 1,
        sortable: false,
      })),
    [colConfig]
  );

  const onCellEditCommit = useCallback(
    (change: { id: GridRowId; field: string; value: string }) => {
      const updatedValue = valuesRef.current.map(val =>
        //@ts-ignore
        val.id === change.id ? { ...val, [change.field]: change.value } : val
      );
      onChange(updatedValue);
    },
    [onChange]
  );

  return (
    <div style={{ width: "100%" }}>
      <StyledDataGrid
        rows={value}
        columns={columns}
        editMode="cell"
        onCellEditCommit={onCellEditCommit}
        rowHeight={80}
        sx={{
          fontSize: "20px",
          fontStyle: "normal",
          borderRadius: "8px",
          border: "1px solid #386FB2",
        }}
        autoHeight
        hideFooter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
      />
    </div>
  );
};

const QUESTION_TYPE_VS_ANSWER_INPUT = {
  [QUESTION_TYPE.MCQ]: MCQAnswerInput,
  [QUESTION_TYPE.TEXT]: TextAnswerInput,
  [QUESTION_TYPE.TABLE]: TableAnswerInput,
};

const AnswerInput = ({
  path,
  answerConfig,
  value,
  onAction,
}: {
  path: string;
  answerConfig: AnswerConfig;
  onAction: (action: AnswerFieldChangeAction) => void;
  value: any;
}) => {
  const Component = QUESTION_TYPE_VS_ANSWER_INPUT[answerConfig.type];

  const onChange = useCallback(
    (value: any) => {
      const _path = path.split(".").map((index: string) => +index - 1);
      onAction({
        type: ACTION_TYPES.ANSWER_FIELD_CHANGE,
        payload: {
          path: _path,
          value,
        },
      });
    },
    [path, onAction]
  );

  return (
    //@ts-ignore
    <Component answerConfig={answerConfig} onChange={onChange} value={value} />
  );
};

export { AnswerInput };
