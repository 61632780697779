import { ReactElement, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';

import Login from 'pages/login';
import Signup from 'pages/signup';
import Home from 'pages/Home';
import Test from 'pages/Test';
import NoAvailableTest from 'pages/NoAvailableTest';

import { NotificationContextProvider } from 'context/NotificationContext';
import ErrorBoundary from 'components/ErrorBoundary';

import { apiClient } from 'services/api';

import { withDeviceAndViewportSize } from 'decorators/withDeviceAndViewportSize';

const defaultTheme = createTheme({
  spacing: (factor: number) => `${0.4 * factor}rem`,
  typography: {
    fontFamily: [
      'Lato',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

function App(): ReactElement {
  useEffect(() => {
    apiClient.get('sanctum/csrf-cookie');
  }, []);

  return (
    <ErrorBoundary>
      <ThemeProvider theme={defaultTheme}>
        <NotificationContextProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/exams" element={<Home />} />
              <Route path="/test" element={<Test />} />
              <Route path="/noAvailableTest" element={<NoAvailableTest />} />
              <Route path="/" element={<Login />} />
            </Routes>
          </BrowserRouter>
        </NotificationContextProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default withDeviceAndViewportSize(App);
