//lib
import { ReactElement } from "react";

//components
import { MobileSignup } from "modules/signup/components/MobileSignup";
import { WebSignup } from "modules/signup/components/WebSignup";

//hooks
import { useIsMobileView } from "modules/device/hooks/useIsMobileView";

export default function Signup(): ReactElement {
  const isMobileView = useIsMobileView();

  return isMobileView ? <MobileSignup /> : <WebSignup />;
}
